.modal-background {
    /*background-color: rgba(100, 100, 100, 0.85);*/
    display: flex;
    height: 100%;
    background-color: #1C1C1C;
}

.modal-content {
    background: #000;
    min-width: 700px;
    color: white;
    border-radius: 10px;
    padding: 40px;
    max-width: 800px;
    margin: auto;
}

.modal-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    color: white;
}

.close-button {
    color: white;
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    float: right;
}

.modal-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.modal-label {
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #999;
}

.modal-textarea {
    background: transparent;
    border: none;
    border-bottom: 1px solid #777;
    color: white;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 10px 0;
}
.modal-input {
    background: transparent;
    border: none;
    border-bottom: 1px solid #777;
    color: white;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 10px 0;
    margin: 0 15px 0 0
}

.modal-textarea {
    border: 1px solid #777;
    min-height:100px
}

.modal-form-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-submit-button {
    background-color: #333;
    color: white;
    border: 1px solid #555;
    padding: 15px 30px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    width: 100%;
}

.modal-submit-button:hover {
    background-color: #555;
}

@media (max-width: 768px) {
    .modal-content {
        padding: 20px;
    }


    .modal-form-inline {
        display: block;
    }
}
