.landingPage {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.logoImg{
  flex-grow: 1;
  max-width: 40%;
  max-height: 40%;
}

.buttonArea{
  display: flex;
}

@media (max-width: 768px) {
  .buttonArea{
    display: block;
  }
}


.buttonAction{
  width: 318px;
  margin: 10px;
/*  height: 48px;*/
  border-radius: 60px;
  border-width: 0;
  padding: 12px 0 12px 0;
  background-color: rgba(221, 242, 71, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgba(19, 60, 64, 1);
  font-family: 'Trenda', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}

.buttonActionSecondary{
  width: 318px;
  margin: 10px;
  /*  height: 48px;*/
  border-radius: 60px;
  border-width: 0;
  padding: 12px 0 12px 0;
  background-color: #4900a3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #FFF;
  font-family: 'Trenda', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  cursor: pointer;
}

.footer {
  text-align: center;
  line-height: 19.2px;
}

.footer p {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Trenda', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}

/*

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
